.submission-completed-container {
  margin: 0;
  padding: 0;
  height: calc(100vh - 70px);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url("../../assets/Images/po-landing-background.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.submission-text-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0px 60px;
  padding: 0px 60px;
}
.submission-inner-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  justify-content: center;
  width: 100%;
}
.submission-text-heading {
  font-family: "TenorSans";
  font-size: 40px;
  font-weight: 500;
  line-height: 53.08px;
  text-align: center;
  width: 1024px;
  height: 53px;
  background-clip: text;
  color: var(--black);
  margin: 0 auto;
}
.arrow {
  width: 16px;
  height: 16px;
}
.submission-text-description {
  font-family: "TenorSans";
  font-size: 24px;
  font-weight: 400;
  line-height: 34px;
  text-align: center;
  width: 832px;
  height: 102px;
  color: var(--black);
  margin: 0 auto;
  gap: 60px;
}
.submission-text-button-container {
  background: var(--white);
  width: 622px;
  height: 70.33px;
  border-radius: 190px 0px 0px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.submission-text-button {
  background-color: var(--red);
  font-family: TenorSans;
  font-size: 16px;
  font-weight: 500;
  line-height: 18.78px;
  text-align: left;
  color: var(--white);
  border: none;
  height: 56px;
  padding: 0px 24px 0px 24px;
  display: flex;
  align-items: center;
  gap: 4px;
  border-radius: 999px;
  cursor: pointer;
}
.hello-container-heading {
  width: 1024px;
  height: 106px;
  font-size: 80px;
  line-height: 106.16px;
}
.hello-container-description {
  width: 540px;
  height: 68px;
  font-size: 24px;
  line-height: 34px;
  gap: 40px;
}
.initial-text-end-text {
  margin-top: 126px;
  background: linear-gradient(90deg, #ff0000 0%, #fb0000 42%, #000000 100%);
  font-family: TenorSans;
  font-size: 16px;
  font-weight: 500;
  line-height: 18.78px;
  text-align: center;
  background-clip: text;
  color: transparent;
}
.info-hello-cancel-container {
  display: flex;
  width: 100%;
  align-items: right;
  padding: 0px 60px;
  justify-content: end;
  gap: 20px;
  margin-top: 30px;
  margin-bottom: 57.5px;
}
.buttonForHome {
  background-color: #fb0000;
  border: none;
  color: white;
  text-align: center;
  padding: 10px;
  border-radius: 40px;
}
.submission-aura-icon-container {
  width: 622px;
}
.submission-aura-icon {
  width: 100%;
}
@media screen and (max-width: 768px) {
  .submission-text-heading {
    font-size: 20px;
    line-height: 20px;
    width: 90%;
  }

  .submission-text-description {
    font-size: 16px;
    line-height: 24px;
    width: 90%;
  }

  .submission-text-button-container {
    width: 90%;
    height: 50px;
    border-radius: 999px;
  }

  .hello-container-heading {
    width: 90%;
    height: 106px;
    font-size: 60px;
    line-height: 106.16px;
  }
}
