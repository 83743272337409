.admin-tabs-table-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.searchField {
  width: 200px;
  align-self: flex-end;
}
.tableHead {
  font-weight: 900 !important;
  border-bottom: 2px solid gray !important;
  font-family: NotoSerif !important;
}
.tableCell {
  font-family: TenorSans !important;
}
.fileName {
  font-family: TenorSans !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  color: #000000 !important;
}
