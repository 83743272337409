.summary-container {
  padding: 32px 60px 40px 60px;
}
.summary-details {
  display: flex;
  gap: 60px;
  position: sticky;
  bottom: 0px;
  background-color: white;
  padding-top: 4px;
  padding-bottom: 40px;
}
.summary-description {
  width: 65.4%;
}
.summary-responses {
  width: 41.8%;
}
.summary-responses.expanded {
  width: 47.6%;
}
.heading-container {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  gap: 3px;
}
.summary-heading {
  font-family: TenorSans;
  font-size: 24px;
  font-weight: 500;
  line-height: 31.85px;
  text-align: left;
  color: #2b2b2b;
  margin-bottom: 40px;
}
.summary-subheading {
  font-family: TenorSans;
  font-size: 16px;
  font-weight: 700;
  line-height: 26px;
  text-align: left;
  color: #2b2b2b;
}
.summary-paragraph {
  font-family: TenorSans;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  text-align: left;
  color: #2b2b2b;
  margin-bottom: 40px;
}
.summary-question {
  font-family: TenorSans;
  font-size: 16px;
  font-weight: 600;
  line-height: 26px;
  text-align: left;
  /* color: #2b2b2b; */
}
.summary-functionalities-list {
  font-family: TenorSans;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  text-align: left;
  margin-left: 50px;
  color: #2b2b2b;
  margin-bottom: 40px;
}
.summary-specs-list {
  width: 85%;
  margin-left: 50px;
}
.specs-subheading {
  font-family: TenorSans;
  font-size: 16px;
  font-weight: 700;
  line-height: 26px;
  text-align: left;
  color: #2b2b2b;
  margin-bottom: 16px;
}
.module-feature {
  font-family: TenorSans;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  color: #2b2b2b;
  margin-bottom: 10px;
}
.module-feature-heading {
  font-family: TenorSans;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  color: #2b2b2b;
}
.module-flow-list {
  margin-left: 50px;
}
.module-flow-list li {
  font-family: TenorSans;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  color: #2b2b2b;
  margin-bottom: 10px;
}
.summary-answer {
  word-wrap: break-word;
  max-width: 600px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  font-family: TenorSans;
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
  text-align: left;
  color: #7a7f84;
}
.edit-answer {
  margin-bottom: 20px;
  overflow-y: auto;
  color: #232323;
}
.responses-unordered-list {
  list-style-type: none;
  padding: 12px;
  background-color: #f6f6f6;
  height: fit-content;
  border-radius: 40px;
  margin-bottom: 40px;
}
.response-heading {
  color: #2b2b2b;
  font-family: TenorSans;
  font-size: 24px;
  font-weight: 500;
  line-height: 31.85px;
  text-align: left;
}
.response-list {
  margin-bottom: 10px;
  cursor: pointer;
  padding: 24px;
}
.response-list:hover {
  background-color: #ffffff;
  border-radius: 32px;
}
.update-field {
  width: 100% !important;
  padding-left: 10px !important;
  margin-top: 10px !important;
  padding: 16px;
  margin-bottom: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.review-field {
  width: 100% !important;
  border: 1px solid #747474 !important;
  padding-left: 17px !important;
  margin-top: 40px !important;
  border-radius: 100px;
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: TenorSans;
}
.generate-spec-button {
  width: 231px !important;
  border-radius: 999px !important;
  color: #ffffff !important;
  border: none !important;
  font-family: TenorSans !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  text-transform: none !important;
  background-color: #ff0404 !important;
  padding: 8px 16px !important;
  box-shadow: none !important;
}
.regenrate-container {
  width: 100%;
  display: flex;
  justify-content: center;
  font-family: TenorSans;
}
.regenerate-button {
  width: 212px !important;
}
.question-answer {
  margin-top: 80px;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.responses-heading-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 34px;
  position: relative;
}
.info-cancel-container-summary {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: absolute;
}
.info-icon-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}
.info-icon {
  width: 24px;
  cursor: pointer;
}
.hover-content {
  display: none;
  transition:
    opacity 0.3s ease,
    visibility 0.3s ease;
  margin-left: 8px;
  color: #585858;
  font-family: TenorSans;
}
.disable-generate-spec {
  border-radius: 40px !important;
  text-transform: none !important;
}
.info-container-summary {
  padding: 8px 24px;
  border: 1px solid #f7c5c5;
  border-radius: 999px;
  display: flex;
  position: absolute;
  right: 60px;
}
.info-container-summary:hover .hover-content {
  display: flex;
}
.back-button {
  padding: 8px 24px;
  border: 1px solid #f7c5c5;
  border-radius: 999px;
  display: flex;
  cursor: pointer;
}
.info-container-summary:hover {
  padding: 8px 16px;
  border-radius: 20px;
  background-color: #f6f6f6;
  display: flex;
  width: fit-content;
  align-items: center;
  border: none;
  cursor: pointer;
}
.update-message {
  font-family: TenorSans;
  font-size: 12px;
  font-style: italic;
  font-weight: 500;
  line-height: 14.09px;
  text-align: left;
  color: #ff0404;
}
.summary-description.dimmed {
  opacity: 0.3;
  transition: opacity 0.3s ease;
  width: 47.6%;
}
.update-field .MuiOutlinedInput-root {
  border-radius: 40px !important;
  /* padding: 0 !important; */
  /* align-items: end; */
}
.update-field .MuiInputBase-input {
  margin: 0px 10px 0px 18px !important;
}
/* .update-field .MuiButtonBase-root{
  margin-bottom: 16px;
  margin-right: 16px;
  width:104px;
} */
.response-text {
  color: var(--black);
  font-family: TenorSans;
  font-size: 24px;
  font-weight: 500;
  line-height: 31.85px;
  text-align: center;
}
.disable-generate-spec {
  border-radius: 40px !important;
  text-transform: none !important;
}
.hide {
  display: none;
}
.extra-class {
  padding: 0px;
}
