.custom-button {
  border-radius: 999px !important;
  color: #ffffff !important;
  border: none !important;
  width: 84px;
  padding: 10px !important;
  background-color: #ff0404 !important;
  cursor: pointer;
}

.custom-button:disabled {
  background-color: #e0e3e6 !important;
}

.custom-button:hover {
  background-color: #ff0404 !important;
  box-shadow: none !important;
  border: none !important;
}
