.admin-tabs-table-container {
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.textField {
  min-width: 200px !important;
}
.select-dropdown {
  min-width: 200px !important;
}
.searchField {
  max-width: 500px !important;
}
.buttons {
  display: flex;
  gap: 10px;
}
.icon {
  width: 30px;
  height: 30px;
  cursor: pointer;
}
.tableHead {
  font-weight: 900 !important;
  border-bottom: 2px solid gray !important;
}
.muiButton {
  text-transform: none !important;
}
.empty-dash {
  text-align: center;
}
