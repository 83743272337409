.inputFieldWrapper {
  display: flex;
  flex-direction: column;
  position: relative;
}
.labelText {
  font-size: 15px;
  color: black;
  line-height: 10px;
  padding-bottom: 10px;
}
.inputField {
  width: 300px;
  min-height: 20px;
  font-size: 16px;
  padding: 10px;
}
.showPassword.passwordHide {
  background-size: 100%;
  color: var(--light-white);
  background-repeat: no-repeat;
  background-image: url(../../assets/Images/eyeSlash.svg);
}

.showPassword.passwordShow {
  background-size: 100%;
  background-repeat: no-repeat;
  background-image: url(../../assets/Images/eyeOpen.svg);
}
.showPassword {
  position: absolute;
  right: 10px;
  width: 24px;
  height: 24px;
  cursor: pointer;
}
.errorIcon {
  position: absolute;
  right: 0px;
  top: 50%;
  transform: translateY(-50%);
}

.errorText {
  color: var(--orange);
  font-size: 12px;
  margin-top: 4px;
}
