.userDashboard {
  display: flex;
  flex-direction: column;
  padding: 20px 23px;
}
.logoWrapper {
  display: flex;
  column-gap: 5px;
}
.headingText {
  font-size: 30px;
  color: black;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  margin: 0px;
}
.buttonStyle {
  background-color: #4caf50;
  color: white;
  padding: 10px 50px;
  text-align: center;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  border: none;
}
.headerContainer {
  display: flex;
  justify-content: space-between;
  padding-bottom: 28px;
}
.headerText {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 32px;
  line-height: 42px;
  padding: 0 121px 104px 121px;
  font-family: TenorSans;
  color: var(--grey);
  text-align: center;
}
