.header-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 70px;
  flex-direction: row;
  padding: 30px 15px 30px 32px;
  background: #ffffffcc;
  border-bottom: 1px solid #d7d7d7;
  position: sticky;
  top: 0;
  z-index: 999;
  background-color: #ffffff;
}
.auraLogoContainer {
  display: flex;
  gap: 12px;
  align-items: center;
  cursor: pointer;
}
.auraLogo {
  box-shadow: 2px 2px 40px 0px #ff00004d;
  border-radius: 50%;
}
.profileLogoContainer {
  display: flex;
  gap: 12px;
  align-items: center;
  cursor: pointer;
}
.dropdown {
  cursor: pointer;
}
.experienceAura {
  font-family: TenorSans;
  font-size: 32px;
  font-weight: 300;
  line-height: 43.58px;
  /* text-align: left;
  background: linear-gradient(90deg, #ff0000 0%, #fb0000 42%, #000000 100%);
  background-clip: text; */
  color: #000000;
}
.confirmButtonStyle {
  background-color: #2b2b2b;
  color: white;
  border-radius: 5px;
  padding: 5px 10px;
  font-size: 16px;
  font-weight: 500;
  border-color: #d7d7d7;
  cursor: pointer;
}
.profile {
  cursor: pointer;
}
.logout-button {
  background-color: var(--red);
  font-family: TenorSans;
  font-size: 16px;
  font-weight: 500;
  line-height: 18.78px;
  text-align: left;
  color: var(--white);
  border: none;
  height: 40px;
  padding: 0px 24px 0px 24px;
  display: flex;
  align-items: center;
  gap: 4px;
  border-radius: 999px;
  cursor: pointer;
}
.header-right-container {
  justify-content: space-between;
  align-items: center;
  width: 238px;
  display: flex;
}
.header-home {
  display: flex;
  align-items: center;
  width: 106px;
  height: 56px;
  justify-content: center;
  text-decoration: none;
  color: #2b2b2b;
  cursor: pointer;
  font-family: TenorSans;
}
.header-line-separator {
  width: 1px;
  height: 24px;
  background-color: #cecece;
}
.header-logout {
  display: flex;
  width: 106px;
  height: 56px;
  align-items: center;
  justify-content: center;
  color: #2b2b2b;
  cursor: pointer;
  font-family: TenorSans;
}
