.loginBackground {
  background-image: url("../../assets/Images/login-bg.png");
  background-size: contain;
  /* background-position: center; */
  height: calc(100vh - 71px);
  display: flex;
  justify-content: flex-end;
  justify-content: flex-end;
  align-items: center;
  padding: 20px;
  background-repeat: no-repeat;
}

.loginContainer {
  width: 400px;
  /* height: 468px; */
  margin-right: 150px;
}
.loginPageWrapper {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 16px;
}

.textRole {
  font-family: TenorSans;
  font-size: 40px;
  font-weight: 500;
  line-height: 53.08px;
  width: 139px;
  height: 53px;
  background: linear-gradient(90deg, #ff0000 0%, #fb0000 42%, #2b2b2b 100%);
  background-clip: text;
  color: transparent;
  text-align: left;
  margin-bottom: 8px;
}

.textRoleDesc {
  font-family: TenorSans;
  font-size: 16px;
  font-weight: 300;
  line-height: 26px;
  color: var(--black);
  height: 52px;
  max-width: 400px;
  margin-bottom: 40px;
  white-space: pre-line;
}

.inputFieldWrapperEmail {
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-bottom: 40px;
  gap: 4px;
  margin-bottom: 40px;
}
.inputFieldWrapperPassword {
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-bottom: 50px;
}

.inputFieldWrapperEmail input {
  border: none;
  border-bottom: 1px solid var(--black);
  color: var(--black);
  padding: 0px 0px 16px 0px;
  width: 400px;
  height: 40px;
  font-size: 16px;
  outline: none;
  transition: border-bottom 0.3s ease;
  font-family: "TenorSans";
}
.inputFieldWrapperPassword input {
  border: none;
  border-bottom: 1px solid var(--light-white);
  color: var(--light-white);
  padding: 0px 0px 16px 0px;
  width: 400px;
  height: 40px;
  font-size: 16px;
  outline: none;
  transition: border-bottom 0.3s ease;
  font-family: "TenorSans";
}
.errorText {
  font-family: TenorSans;
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  line-height: 14.09px;
  text-align: right;
  color: var(--orange);
}

.loginButton {
  width: 131px;
  height: 56px;
  border-radius: 999px;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  cursor: pointer;
  background-color: var(--dark-white);
  color: var(--white);
  border: none;
  transition: background-color 0.3s ease;
}

.loginButton:disabled {
  background-color: var(--dark-white);
  color: var(--white);
}

.loginButton:hover:enabled {
  background-color: var(--medium-black);
}

.contactSupport {
  font-family: "TenorSans", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 18.78px;
  color: var(--light-grey);
  width: 400px;
  height: 19px;
  text-align: left;
  margin-top: 16px;
}

.contactSupport a {
  color: var(--dark-red);
  text-decoration: none;
}

.contactSupport a:hover {
  text-decoration: underline;
}

.custom-snackbar {
  top: 80px !important;
}
