.details-upload-container {
  display: flex;
  width: 100%;
  height: 100%;
  padding: 0 60px;
  margin-top: 30px;
}

.upload-content-heading {
  width: 100%;
  font-family: TenorSans;
  font-size: 40px;
  font-weight: 500;
  line-height: 53.08px;
  text-align: left;
}
.upload-content-description {
  font-family: TenorSans;
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  text-align: left;
  width: 486px;
  height: 26px;
  color: #2b2b2b;
}

.info-cancel-container {
  width: 100%;
  display: flex;
  gap: 16px;
  justify-content: flex-end;
  align-items: center;
}
.info-icon,
.cancel-icon {
  width: 24px;
  height: 24px;
  cursor: pointer;
}
.hover-content {
  display: none;
  transition:
    opacity 0.3s ease,
    visibility 0.3s ease;
  margin-left: 8px;
  color: #585858;
  font-family: TenorSans;
}
.info-container:hover .hover-content {
  display: flex;
}
.info-container,
.cancel-container {
  padding: 8px 24px;
  border: 1px solid #f7c5c5;
  border-radius: 999px;
  display: flex;
  cursor: pointer;
}
.info-container:hover {
  padding: 8px 16px;
  border-radius: 20px;
  background-color: #f6f6f6;
  display: flex;
  width: fit-content;
  align-items: center;
  border: none;
  cursor: pointer;
}
.figma-ordered-list {
  width: 586px;
  height: 288px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  font-family: TenorSans;
  font-size: 16px;
  font-weight: 400;
  border-radius: 40px;
  line-height: 26px;
  text-align: left;
  margin-left: 17px;
}
.guidelines-container {
  display: flex;
  font-family: TenorSans;
  flex-direction: column;
  width: 586px;
}
.guidelines-heading {
  height: 344px;
  overflow: auto;
  scrollbar-width: none;
}
.discover-container {
  display: flex;
  flex-direction: column;
  gap: 65px;
}
.figma-upload-container {
  width: 600px;
  height: 429px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  border: 1px dashed #ff0404;
  padding: 24px;
  box-shadow: 0px 4px 30px 0px #ff30301a;
  border-radius: 40px;
}
.figma-img {
  width: 42.67px;
  height: 64px;
  align-items: center;
}
.figma-img-pending {
  width: 195.14px;
  border: 1px 0px 0px 0px;
}
.upload-container {
  display: flex;
  gap: 60px;
  margin-top: 90px;
  padding: 0px 60px;
  margin-bottom: 20px;
}
.discover-text {
  font-family: TenorSans;
  font-size: 16px;
  font-weight: 500;
  line-height: 18.78px;
  color: #ff0404;
  width: 360px;
  height: 19px;
  margin-top: 65px;
  top: 689px;
  left: 720px;
  cursor: pointer;
}
.upload-button {
  width: 123px;
  height: 48px;
  padding: 0px 24px 0px 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  border-radius: 999px;
  border: 1px 0px 0px 0px;
  cursor: pointer;
  background: #ffffff;
  border: 1px solid #f7c5c5;
  color: #ff0404;
}

.figma-description {
  width: 226px;
  height: 66px;
  font-family: Nunito Sans;
  font-size: 24px;
  font-weight: 400;
  line-height: 32.74px;
  text-align: center;
}
.figma-reupload-description {
  width: 226px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  font-family: Nunito Sans;
  font-size: 24px;
  font-weight: 400;
  line-height: 32.74px;
  text-align: center;
}
.guidelines-text {
  color: #2b2b2b;
  font-family: TenorSans;
  font-size: 24px;
  font-weight: 500;
  line-height: 31.85px;
  text-align: left;
  width: 586px;
  height: 32px;
  margin-bottom: 24px;
}
.upload-pending {
  font-family: TenorSans;
  font-size: 24px;
  font-weight: 500;
  line-height: 31.85px;
  text-align: center;
  width: 226px;
  height: 32px;
  color: #2b2b2b;
}
.linear-progress-bar {
  width: 60.5%;
  border: 0.5px solid;
  border-image-source: linear-gradient(
    90deg,
    #ff0000 0%,
    #fb0000 51.26%,
    #ffffff 100%
  ) !important;
  background-color: transparent !important;
  height: 2px !important;
}
.generate-design {
  width: 265px;
  height: 48px;
  padding: 0px 24px 0px 24px;
  gap: 8px;
  border-radius: 999px;
  background: linear-gradient(252.98deg, #ff3f3f 3.19%, #ff0909 101.73%);
  color: #ffffff;
  border: none;
  cursor: pointer;
}
.guidelines-fadeeffect {
  background: linear-gradient(180deg, #ffffff 85%, #f5f4f4 100%);
  height: 328px;
  overflow: auto;
  scrollbar-width: none;
}
