@import url("https://fonts.googleapis.com/css2?family=TenorSans:ital,wght@0,100..900;1,100..900&display=swap");

.parentContainer .contentContainer {
  height: 100%;
}

/* colors */
:root {
  --dark-black: #000000;
  --red: #ff0404;
  --background-color: #f3f3f3;
  --white: #ffffff;
  --grey: #4d4b4b;
  --dark-white: #e0e3e6;
  --black: #2b2b2b;
  --light-white: #b2b2b2;
  --orange: #ff7a00;
  --medium-black: #1a1a1a;
  --light-grey: #666;
  --dark-red: #ff0000;
  --global-gradient: linear-gradient(
    90deg,
    #ff0000 0%,
    #fb0000 42%,
    #2b2b2b 100%
  );
  --light-pink: #f7c5c5;
}

/* @font-face {
  font-family: TenorSans;
  src: url(./assets/Fonts/SwearDisplay/fonnts.com-Swear-Display-.otf);
} */
/* @font-face {
  font-family: TenorSans Medium;
  src: url(./assets/Fonts/SwearDisplay/fonnts.com-Swear-Display-Medium.otf);
} */
/* @font-face {
  font-family: TenorSans;
  src: url(./assets/Fonts/TenorSans/TenorSans-Regular.ttf);
} */
/* @font-face {
  font-family: TenorSans MediumFont;
  src: url(./assets/Fonts/TenorSans/TenorSans-Medium.ttf);
} */
/* @font-face {
  font-family: TenorSans BoldFont;
  src: url(./assets/Fonts/TenorSans/TenorSans-Bold.ttf);
} */
/* @font-face {
  font-family: TenorSans SemiBold;
  src: url(./assets/Fonts/TenorSans/TenorSans-SemiBold.ttf);
} */
@font-face {
  font-family: TenorSans;
  src: url(./assets/Fonts/TenorSans-Regular.ttf);
}
