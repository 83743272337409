.coming-soon-details-modal-submit-button {
  display: inline-block;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  background-color: #000;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  outline: none;
  cursor: pointer;
}
.modal-list-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 24px;
  font-size: 16px;
  width: 500px;
  height: 370px;
  gap: 16px;
  font-family: TenorSans;
  font-size: 16px;
  font-weight: 400;
  border-radius: 40px;
  line-height: 26px;
  text-align: left;
  margin-left: 17px;
}

.modal-list-heading {
  font-size: 24px;
  font-family: TenorSans;
  color: var(--black);
  margin: 0;
  padding: 0;
}
.modal-child-container {
  font-family: TenorSans;
  font-size: 16px;
  font-weight: 400;
}
.modal-confirm-button {
  padding: 10px 24px;
  gap: 8px;
  font-family: TenorSans;
  font-size: 16px;
  font-weight: 400;
  border-radius: 999px;
  border: 1.5px solid var(--light-pink);
  background-color: var(--white);
  color: var(--red);
  cursor: pointer;
}
