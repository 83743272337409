* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.questionaire {
  display: flex;
  align-items: start;
  height: calc(100vh - 88px);
}
/* .numbers {
  position: relative;
  min-width: 261px;
} */

.numbers {
  background: linear-gradient(180deg, #f5f5f5 -11.05%, #d9d9d9 100%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
  font-family: TenorSans;
  font-size: 420px;
  font-weight: 500;
  /* position: absolute;
  top: -60px; */
  min-width: 261px;
  text-align: right;
}

.question-answered-button-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  position: relative;
}

.questions-container {
  padding: 20px;
  color: #fff;
  height: calc(100vh - 88px);
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.answered-questions {
  width: 100%;
  max-width: 600px;
  overflow-y: auto;
  margin-bottom: 20px;
}

.answered-question {
  /* padding: 10px; */
  /* height: 60px; */
  margin-bottom: 10px;
  border-radius: 4px;
  cursor: pointer;
  max-width: 700px;
  transition: all 0.3s ease-in-out;
  animation: scrollUp 0.3s ease-in-out forwards;
  opacity: 0.5;
  color: #232323;
}

.current-question {
  width: 91%;
  max-width: 1024px;
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 20px;
}

.answer-input {
  margin-bottom: 20px;
  overflow-y: auto;
  color: #232323;
}
.question-input {
  font-family: "TenorSans";
  font-size: 40px;
  font-weight: 500;
  line-height: 53.08px;
  text-align: left;
  margin-bottom: 21vh;
  color: rgba(43, 43, 43, 1);
}
.message {
  font-family: "TenorSans";
  font-size: 40px;
  font-weight: 500;
  line-height: 53.08px;
  color: rgba(43, 43, 43, 1);
}
.current-question-button {
  padding: 10px !important;
  cursor: pointer;
  width: 84px !important;
  border-radius: 999px !important;
  color: #ffffff !important;
  border: none !important;
}

.answered-questions-container {
  width: 91%;
  max-width: 1024px;
  height: 80px;
  font-family: "TenorSans";
  overflow-y: auto;
  /* margin-bottom: 20px;
  margin-top: 20px; */
  -ms-overflow-style: none;
  scrollbar-width: none;
  position: absolute;
  top: 10px;
  /* right: 240px; */
}
.answered-questions-container::-webkit-scrollbar {
  display: none;
}

.answer {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  width: 540px;
  margin-top: 15px;
  font-size: 12px;
}
.buttonForHome {
  cursor: pointer;
}
.question {
  font-size: 16px;
}
.buttons-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.next-button-container {
  display: flex;
  width: 100%;
  justify-content: end;
}
.info-cancel-container-questions {
  /* width: 100%; */
  gap: 16px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: absolute;
  right: 60px;
}
.info-icon {
  width: 24px;
  cursor: pointer;
}
.hover-content {
  display: none;
  transition:
    opacity 0.3s ease,
    visibility 0.3s ease;
  margin-left: 8px;
  color: #585858;
  font-family: "TenorSans";
}
.info-container:hover .hover-content {
  display: flex;
}
.info-container {
  padding: 8px 24px;
  border: 1px solid #f7c5c5;
  border-radius: 999px;
  display: flex;
}
.info-container:hover {
  padding: 8px 16px;
  border-radius: 20px;
  background-color: #f6f6f6;
  display: flex;
  width: fit-content;
  align-items: center;
  border: none;
  cursor: pointer;
}

@keyframes scrollUp {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0%);
  }
}

@keyframes scrollDown {
  0% {
    transform: translateY(0%);
  }
  100% {
    transform: translateY(100%);
  }
}
.progress-container {
  width: 100%;
  background-color: #e0e0e0;
  border-radius: 1px;
  height: 2px;
  margin-bottom: 16px;
  position: sticky;
  top: 70px;
  z-index: 999;
}
.progress-bar {
  height: 100%;
  background: linear-gradient(to left, #ff0000, #fb0000, #ffffff);
  border-radius: 1px;
  transition: width 0.3s ease-in-out;
}
