@import "../../global.css";
.roleCardWrapper {
  display: flex;
  column-gap: 24px;
  justify-content: center;
  align-items: center;
}
.roleCardContainer {
  background-color: var(--background-color);
  width: 280px;
  border-radius: 12px;
  padding: 24px;
  min-height: 150px;
  position: relative;
  cursor: pointer;
}
.roleCardContainer.hoverStyle:hover {
  background-color: var(--red);
  color: var(--white);
  box-shadow: 2px 5px 30px 0px rgba(255, 4, 4, 0.2);
}
.cardContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 18px;
}
.rolesWrapper {
  display: flex;
  column-gap: 24px;
  justify-content: center;
  align-items: center;
}
.roleText {
  font-size: 16px;
  line-height: 18px;
  margin: 0;
  font-weight: 500;
  font-family: TenorSans;
  background: linear-gradient(90deg, #ff0000 0%, #fb0000 42%, #000000 100%);
  background-clip: text;
  color: transparent;
}
.roleText.textHover:hover {
  color: var(--white);
}
.mainContainer {
  display: flex;
  flex-direction: row;
}
.comingSoonText {
  font-family: TenorSans;
  font-size: 12px;
  line-height: 14px;
  background: linear-gradient(90deg, #cacaca 0%, #747474 42%, #747474 100%);
  background-clip: text;
  color: transparent;
  padding: 6px 10px;
}
.imageContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.comingSoonWrapper {
  background-color: var(--white);
  border: 1px solid var(--white);
  border-radius: 999px;
}
.roleCardContainer.hoverStyle:hover .roleText {
  color: var(--white);
}
.cardWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: relative;
  min-height: 180px;
}
.knowMoreText {
  font-family: Railway Regular;
  font-weight: 500;
  font-size: 16px;
  line-height: 18.78px;
  background: linear-gradient(90deg, #ff0000 0%, #fb0000 42%, #000000 100%);
  background-clip: text;
  color: transparent;
  opacity: 0;
  /* transition: opacity 0.3s ease-in-out; */
  position: absolute;
  /* bottom: px; Adjust this based on your needs */
  top: 155px;
  left: 182px;
  /* transform: translateX(-50%); */
}
.roleCardContainer.hoverStyle:hover .knowMoreText,
.knowMoreText:hover {
  opacity: 1;
  cursor: pointer;
}
.roleCardContainer.hoverStyle:hover .knowMoreText,
.cardWrapper:hover .knowMoreText {
  opacity: 1;
  pointer-events: auto;
}
.rolesCard {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 102px;
}
