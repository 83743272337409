.mobile-layout {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background-color: #f5f5f5;
  background: linear-gradient(90deg, #ff0000 0%, #fb0000 42%, #000000 100%);
  font-family: TenorSans;
  font-size: 16px;
  font-weight: 500;
  line-height: 18.78px;
  text-align: left;
  background-clip: text;
  color: transparent;
}
