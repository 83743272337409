.formBackground {
  background-image: url("../../assets/Images/login-bg.png");
  background-size: contain;
  /* background-position: center; */
  height: calc(100vh - 71px);
  display: flex;
  justify-content: flex-end;
  justify-content: flex-end;
  align-items: center;
  padding: 20px;
  background-repeat: no-repeat;
}

.formContainer {
  width: 400px;
  /* height: 468px; */
  margin-right: 150px;
  /* margin-bottom: 80px; */
}

.formPageWrapper {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 16px;
}

.formTextRole {
  font-family: TenorSans;
  font-size: 40px;
  font-weight: 500;
  line-height: 53.08px;
  max-width: 367px;
  /* height: 53px; */
  background: linear-gradient(90deg, #ff0000 0%, #fb0000 42%, #2b2b2b 100%);
  background-clip: text;
  color: transparent;
  text-align: left;
  margin-bottom: 8px;
}

.formtextRoleDesc {
  font-family: TenorSans;
  font-size: 16px;
  font-weight: 300;
  line-height: 26px;
  color: var(--black);
  height: 52px;
  width: 265px;
  margin-bottom: 20px;
  white-space: pre-line;
}

.inputFieldWrapper {
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-bottom: 20px;
}

.textField {
  border: none;
  border-bottom: 1px solid var(--black);
  color: var(--black);
  padding: 0px 0px 16px 0px;
  width: 400px;
  height: 40px;
  font-size: 16px;
  outline: none;
  transition: border-bottom 0.3s ease;
}

.errorText {
  font-family: TenorSans;
  font-size: 12px;
  font-style: italic;
  font-weight: 400;
  line-height: 14.09px;
  text-align: right;
  color: var(--orange);
}

.submitButton {
  width: 131px;
  height: 56px;
  border-radius: 999px;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  cursor: pointer;
  background-color: var(--dark-white);
  color: var(--white);
  border: none;
  transition: background-color 0.3s ease;
}

.submitButton:disabled {
  background-color: var(--dark-white);
  color: var(--white);
}

.submitButton:hover:enabled {
  background-color: var(--medium-black);
}

.snackbarAlert {
  /* width: 50%; */
  top: 80px !important;
}

.snackbarSuccess {
  /* width: 50%; */
  top: 80px !important;
}

.snackbarError {
  top: 80px !important;
}
.firstNameWrapper {
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-bottom: 10px;
  font-family: TenorSans;
}
.firstNameWrapper input {
  border: none;
  border-bottom: 1px solid var(--black);
  color: var(--black);
  padding: 0px 0px 16px 0px;
  width: 400px;
  height: 40px;
  font-size: 16px;
  font-family: TenorSans;
  outline: none;
  transition: border-bottom 0.3s ease;
}

.textAreaWrapper {
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-bottom: 10px;
  font-family: TenorSans;
}
.textAreaWrapper textarea {
  border: none;
  border-bottom: 1px solid var(--black);
  color: var(--black);
  padding: 0px 0px 16px 0px;
  width: 400px;
  height: 50px;
  font-size: 16px;
  outline: none;
  font-family: TenorSans;
  transition: border-bottom 0.3s ease;
}
