.admin-tabs-table-container {
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.form-field {
  min-width: 200px !important;
}
.searchField {
  max-width: 500px !important;
}
.tableHead {
  font-weight: 900 !important;
  border-bottom: 2px solid gray !important;
}
.createUser {
  text-transform: none !important;
}
