.submission-inprogress-container {
  margin: 0;
  padding: 0;
  height: calc(100vh - 70px);
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-image: url("../../assets/Images/po-landing-background.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  align-items: center;
}

.submission-inprogress-text-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;
}

/* .submission-inprogress-text-data {
  top: 90px;
  position: absolute;
} */

.submission-inprogress-thanks-details {
  font-family: "TenorSans";
  font-size: 40px;
  font-weight: 500;
  line-height: 53.08px;
  text-align: center;
  width: 848px;
  height: 53px;
  color: rgba(43, 43, 43, 1);
  margin: 0 auto;
}

.submission-inprogress-analysis-details {
  font-family: "TenorSans";
  font-size: 24px;
  font-weight: 400;
  line-height: 28.18px;
  text-align: center;
  width: 848px;
  height: 28px;
  color: var(--black);
  margin: 0 auto;
}
.loading-dots {
  display: inline-flex;
  margin-left: 5px;
}

.loading-dots span {
  display: inline-block;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: var(--black);
  margin-left: 3px;
  animation: dot-blink 1.5s infinite ease-in-out;
}

.loading-dots span:nth-child(1) {
  animation-delay: 0s;
}

.loading-dots span:nth-child(2) {
  animation-delay: 0.3s;
}

.loading-dots span:nth-child(3) {
  animation-delay: 0.6s;
}

@keyframes dot-blink {
  0%,
  20% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.submission-inprogress-image-container {
  background: var(--white);
  border-radius: 190px 0px 0px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  margin-top: 60px;
}

.submission-inprogress-image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media screen and (max-width: 768px) {
  .submission-inprogress-thanks-details {
    font-size: 20px;
    line-height: 20px;
    width: 90%;
  }

  .submission-inprogress-analysis-details {
    font-size: 16px;
    line-height: 24px;
    width: 90%;
  }

  .submission-inprogress-image-container {
    width: 90%;
    height: 50px;
  }
}
